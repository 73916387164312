<template>
	<div class="pb-1">
		<b-row>
			<b-col cols="12" md="3">
				<router-link to="/settings/auth" class="card icon-card cursor-pointer text-dark text-center">
					<b-card-body>
						<div class="icon-wrapper">
							<feather-icon icon="ImageIcon" size="18" />
						</div>
						<div class="icon-name text-truncate mb-0 mt-1">Authentication Images</div>
					</b-card-body>
				</router-link>
			</b-col>
			<b-col cols="12" md="3">
				<router-link to="/settings/announcements" class="card icon-card cursor-pointer text-dark text-center">
					<b-card-body>
						<div class="icon-wrapper">
							<feather-icon icon="BellIcon" size="18" />
						</div>
						<div class="icon-name text-truncate mb-0 mt-1">Announcements</div>
					</b-card-body>
				</router-link>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BCardBody, BRow, BCol } from "bootstrap-vue";

export default {
	components: {
		BCardBody,
		BRow,
		BCol,
	},
};
</script>
